import React from 'react';
import './Shimmer.css';

const lineShimmer = (props)=>{
    let styled = props.styles;
    
    return(
    <div style={styled} className="comment br animate">

    </div>);
}

export default lineShimmer;